import {EditBase, EditView, Loading, useGetOne, useRecordContext} from 'react-admin';
import RecordTimeLineWrapper from '../recordTimelineWrapper';
import OrderForm from './orderForm';
import OrderEditTitle from './OrderEditTitle';
import OrderEditStatus from './OrderEditStatus';
import {Box, Stack} from '@mui/material';
import {UploadPostButton} from "./uploadPostButton";
import {useGetIdentity} from "../../../hooks/useGetIdentity";
import {v1_Posts} from "../../../../generated/types";

const OrderEdit = () => {

    return (
        <EditBase redirect={false}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="self-start"
                sx={{flexDirection: {xs: 'column', md: 'row'}}}
            >
                <OrderEditTitle/>
                <Stack direction="row" spacing={2}>
                    <UploadComponent/>
                    <OrderEditStatus/>
                </Stack>
            </Box>
            <EditView aside={<RecordTimeLineWrapper/>}>
                <OrderForm/>
            </EditView>
        </EditBase>
    );
};

const UploadComponent = () => {

    const {data: identity, isLoading: isIdentityLoading} = useGetIdentity();
    const post = useRecordContext<v1_Posts>();

    const {data: writter, isLoading: writterLoading} = useGetOne(
        'v1_ContactBase',
        {
            id: identity?.id,
        },
        {enabled: !!identity?.id}
    );

    if (isIdentityLoading || writterLoading || !post) return <Loading/>

    if (!writter?.allow_upload_content_portal || post.is_marketplace_post) return null;

    return (
        <UploadPostButton/>
    )
}
export default OrderEdit;
