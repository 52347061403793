import {Button, Identifier, useNotify, useRecordContext, useRefresh} from "react-admin";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import UploadIcon from '@mui/icons-material/Upload';
import {v1_Posts} from "../../../../generated/types";
import {useState} from "react";
import {sendPostToApi} from "../../../services/api";
import {MARKETPLACE_USER_ID} from "../../../config/constants";

type Payload = {
    post_id: Identifier;
    user_id: Identifier;
};

export const UploadPostButton = () => {

    const record: v1_Posts = useRecordContext();
    const notify = useNotify();
    const refresh = useRefresh();
    const [isUploading, setIsUploading] = useState(false);

    if (!record) return null;

    const handleOnClick = async () => {
        setIsUploading(true);
        await uploadPost({
            post_id: record.id,
            user_id: MARKETPLACE_USER_ID,
        });
    };

    const uploadPost = async (payload: Payload) => {
        try {
            const response = await sendPostToApi('crm/post/upload', {
                ...payload,
            });
            notify(response?.data['message'], {
                type: response?.data['success'] ? 'success' : 'error',
            });
            refresh();
            setIsUploading(false);
        } catch (error: any) {
            notify(error.message, {type: 'error'});
        }
    };

    return (
        <Button
            label="Upload Post"
            onClick={handleOnClick}
            startIcon={
                isUploading ? <HourglassBottomIcon/> : <UploadIcon/>
            }
            variant="contained"
            disabled={isUploading}
        />
    );

}
